import { Injectable, signal } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { WPItem } from "../models/wp-item.model";
import { WordPressService } from "./word-press.service";

@Injectable({
  providedIn: "root",
})
export class CatalogService {
  constructor(
    private wpService: WordPressService,
    private sanitizer: DomSanitizer,
  ) {}

  catalogUrl = signal<string>("");
  catalogFileName = signal<string>("");
  catalogName = signal<string>("");
  catalogCoverUrl = signal<string>("");

  async loadContent(slug: string | undefined) {
    if (!slug) return null;
    try {
      const page = await this.wpService.getPage(slug);
      if (!page) return null;

      this.setCatalogData(page);

      const catalogs =
        page?.acf?.catalogs?.map((catalog: any) => ({
          title: catalog.catalog_name,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(
            catalog.fliphtml5_url,
          ),
          yumpuId: catalog.yumpu_id,
        })) || [];

      return { page, catalogs };
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  setCatalogData(page: WPItem) {
    this.catalogName.set(page?.acf?.catalog_homepage_text || "");
    this.catalogUrl.set(page?.acf?.catalog_url || "");
    this.catalogFileName.set(page?.acf?.current_catalog_file_name || "");
    this.catalogCoverUrl.set(page?.acf?.catalog_cover_url || "");
  }
}
